import { ViralApp } from "./ViralApp.js";
import { RegisterServiceWorker } from "../../../../shared/typescript/AppHelpers/AppServiceWorkerHelper.js";

// welcome your debugging your code, press F5 to continue, F10 to step, F11 to step-into, etc..
// debugger;

ReactDOM.render(
  React.createElement(ViralApp),
  document.querySelector("#appDisplay2"),
);

if (window.opener) {
  try {
    window.opener!.postMessage({ su: "viral" }, '*');

    let onVisibilityChanged = function (e) {
      if (!document.hidden) {

        window.opener!.postMessage({ su: "viral" }, '*');
      }
    };

    document.removeEventListener("visibilitychange", onVisibilityChanged);
    document.addEventListener("visibilitychange", onVisibilityChanged, {
      passive: false,
    });
  } catch (e) { }
}

RegisterServiceWorker(true, true);